import request from './request';
import noEr from "./request_no_er";
import common from "./common";

// ----------------------------公共接口-----------------------------------------
// 获取申办方列表
export function getSponsorInCommon(data) {
  return common({
    url: "/getSponsorList",
    method: "post",
    data
  })
}

// 运营管理员 登录
export function getLogin(data) {
  return noEr({
    url: "/login",
    method: "post",
    data
  })
}

// 发送验证码
export function sendYzm(data) {
  return common({
    url: "/sendVerificationCode",
    method: "post",
    data
  })
}

// 登录发送验证码
export function sendRoleVerificationCode(data) {
  return common({
    url: "/RoleVerificationCode",
    method: "post",
    data
  })
}

// 修改密码
export function getChangPwd(data) {
  return noEr({
    url: "/changePwd",
    method: "post",
    data
  })
}

// 查看费用发放列表
export function getCompensationList(data) {
  return request({
    url: "/compensation/get_compensation_application_list",
    method: "post",
    data
  })
}

// 查看费用发放列表
export function getCompensationApplication(data) {
  return request({
    url: "/compensation/get_compensation_application",
    method: "post",
    data
  })
}

// 费用发放
export function goExpenseRelease(data) {
  return request({
    url: "/issuing/payroll",
    method: "post",
    data
  })
}

// 费用发放 - 导出
export function exportExpenseList(data) {
  return request({
    url: "compensation/exportCompensationList",
    method: "get",
    params: data,
    responseType: 'blob'
  })
}

// ------------------------------ 申办方管理 ------------------------------------------

// 获取申办方列表
export function getSponsorList(data) {
  return request({
    url: "/sponsor/getSponsorList",
    method: "post",
    data
  })
}

// 添加申办方
export function addSponsor(data) {
  return request({
    url: "/sponsor/addSponsor",
    method: "post",
    data
  })
}

// 编辑申办方
export function editSponsor(data) {
  return request({
    url: "/sponsor/editSponsor",
    method: "post",
    data
  })
}

// 删除申办方
export function deleteSponsor(data) {
  return request({
    url: "/sponsor/delSponsor",
    method: "post",
    data
  })
}

// ------------------------------ 研究中心管理 ------------------------------------------

// 获取研究中心列表
export function getHospitalList(data) {
  return request({
    url: "/hospital/getHospitalList",
    method: "post",
    data
  })
}

// 添加研究中心
export function addHospital(data) {
  return request({
    url: "/hospital/addHospital",
    method: "post",
    data
  })
}

// 编辑研究中心
export function editHospital(data) {
  return request({
    url: "/hospital/editHospital",
    method: "post",
    data
  })
}

// 删除研究中心
export function deleteHospital(data) {
  return request({
    url: "/hospital/delHospital",
    method: "post",
    data
  })
}

// 费用类型 - 获取列表
export function getExpenseTypes(data) {
  return request({
    url: "/expenseType/getExpenseTypeList",
    method: "post",
    data
  })
}

// 费用类型 - 新增类型
export function addExpenseType(data) {
  return request({
    url: "/expenseType/addExpenseType",
    method: "post",
    data
  })
}

// 费用类型 - 编辑类型
export function editExpenseType(data) {
  return request({
    url: "/expenseType/editExpenseType",
    method: "post",
    data
  })
}

// 费用类型 - 删除
export function deleteExpenseType(data) {
  return request({
    url: "/expenseType/delExpenseType",
    method: "post",
    data
  })
}

// 项目管理 - 获取列表
export function getProjectList(data) {
  return request({
    url: "/project/getProjectList",
    method: "post",
    data
  })
}

// 项目管理 - 根据申办方ID获取申办方管理员
export function getSponsorsById(data) {
  return common({
    url: "/getSponsorAdminList",
    method: "post",
    data
  })
}

// 项目管理 - 获取项目的申办方管理员信息
export function getProjectSponsorsByProId(data) {
  return request({
    url: "/project/getProjectSponsorAdminList",
    method: "post",
    data
  })
}

// 项目管理 - 新增项目
export function addProject(data) {
  return request({
    url: "/project/addProject",
    method: "post",
    data
  })
}

// 项目管理 - 编辑项目
export function editProject(data) {
  return request({
    url: "/project/editProject",
    method: "post",
    data
  })
}

// 项目管理 - 启用/禁用
export function updateProjectAuth(data) {
  return request({
    url: "/project/enableProject",
    method: "post",
    data
  })
}

// 项目管理 - 删除
export function deleteProject(data) {
  return request({
    url: "/project/delProject",
    method: "post",
    data
  })
}

// 项目管理 - 获取项目下的访视
export function getInterviewsInPro(data) {
  return request({
    url: "/project/getInterviewList",
    method: "post",
    data
  })
}

// 项目管理 - 保存 访视配置
export function saveInterviewDis(data) {
  return request({
    url: "/project/saveInterview",
    method: "post",
    data
  })
}

// 项目管理 - 获取可分配的中心
export function getCenterToDis(data) {
  return request({
    url: "/project/getHospitalList",
    method: "post",
    data
  })
}

// 项目管理 - 保存分配的中心
export function saveCenterForPro(data) {
  return request({
    url: "/project/saveHospital",
    method: "post",
    data
  })
}

// 项目管理 - 获取项目下所有的中心
export function getCentersByProjectId(data) {
  return request({
    url: "/project/getHospitalByProjectId",
    method: "post",
    data
  })
}

// 获取所有研究者
export function getAllResearchers(data) {
  return common({
    url: "/getResearcherList",
    method: "post",
    data
  })
}

// 获取所有CRC
export function getAllCRCs(data) {
  return common({
    url: "/getCRCList",
    method: "post",
    data
  })
}

// CRC移交
export function getTransferCRC(data) {
  return request({
    url: "/CRC/transferCRC",
    method: "post",
    data
  })
}

// 项目管理 - 获取中心已分配的CRC和研究者
export function getCrcAndResInDis(data) {
  return request({
    url: "/project/getHospitalUserList",
    method: "post",
    data
  })
}

// 项目管理 - 保存给中心分配的CRC和研究者
export function saveCenterCRCAndPI(data) {
  return request({
    url: "/project/saveHospitalCRCAndPI",
    method: "post",
    data
  })
}

// 项目管理 - 获取项目下所有的CRA
export function getCrasInPro(data) {
  return common({
    url: "/getCRAByHospitalProjectIdList",
    method: "post",
    data
  })
}

// ------------------------------ 用户角色管理 ------------------------------------------
// CRC - 获取列表
export function getCRCList(data) {
  return request({
    url: "/CRC/getUserList",
    method: "post",
    data
  })
}

// CRC - 增加
export function addCRC(data) {
  return request({
    url: "/CRC/addUser",
    method: "post",
    data
  })
}

// CRC - 编辑
export function editCRC(data) {
  return request({
    url: "/CRC/editUser",
    method: "post",
    data
  })
}

// CRC - 启用/禁用
export function updateCRCAuth(data) {
  return request({
    url: "/CRC/enableUser",
    method: "post",
    data
  })
}

// CRC - 重置密码
export function resetCRCPsd(data) {
  return request({
    url: "/CRC/resetUserPwd",
    method: "post",
    data
  })
}

// CRC - 删除
export function deleteCRC(data) {
  return request({
    url: "/CRC/delUser",
    method: "post",
    data
  })
}

// CRA - 获取列表
export function getCRAList(data) {
  return request({
    url: "/CRA/getUserList",
    method: "post",
    data
  })
}

// CRA - 增加
export function addCRA(data) {
  return request({
    url: "/CRA/addUser",
    method: "post",
    data
  })
}

// CRA - 编辑
export function editCRA(data) {
  return request({
    url: "/CRA/editUser",
    method: "post",
    data
  })
}

// CRA - 启用/禁用
export function updateCRAAuth(data) {
  return request({
    url: "/CRA/enableUser",
    method: "post",
    data
  })
}

// CRA - 重置密码
export function resetCRAPsd(data) {
  return request({
    url: "/CRA/resetUserPwd",
    method: "post",
    data
  })
}

// CRA - 删除
export function deleteCRA(data) {
  return request({
    url: "/CRA/delUser",
    method: "post",
    data
  })
}

// 申办方管理员 - 获取列表
export function getSponsorAdminList(data) {
  return request({
    url: "/sponsorAdmin/getUserList",
    method: "post",
    data
  })
}

// 申办方管理员 - 新增
export function addSponsorAdmin(data) {
  return request({
    url: "/sponsorAdmin/addUser",
    method: "post",
    data
  })
}

// 申办方管理员 - 编辑
export function updateSponsorAdmin(data) {
  return request({
    url: "/sponsorAdmin/editUser",
    method: "post",
    data
  })
}

// 申办方管理员 - 启用/禁用
export function changeSponsorAdminAuth(data) {
  return request({
    url: "/sponsorAdmin/enableUser",
    method: "post",
    data
  })
}

// 申办方管理员 - 删除
export function deleteSponsorAdmin(data) {
  return request({
    url: "/sponsorAdmin/delUser",
    method: "post",
    data
  })
}

// 申办方管理员 - 重置密码
export function resetSponsorAdminPsw(data) {
  return request({
    url: "/sponsorAdmin/resetUserPwd",
    method: "post",
    data
  })
}

// 研究者 - 获取列表
export function getResearcherList(data) {
  return request({
    url: "/researcher/getUserList",
    method: "post",
    data
  })
}

// 研究者 - 新增
export function addResearcher(data) {
  return request({
    url: "/researcher/addUser",
    method: "post",
    data
  })
}

// 研究者 - 编辑
export function editResearcher(data) {
  return request({
    url: "/researcher/editUser",
    method: "post",
    data
  })
}

// 研究者 - 启用/禁用
export function updateResearcherAuth(data) {
  return request({
    url: "/researcher/enableUser",
    method: "post",
    data
  })
}

// 研究者 - 重置密码
export function resetResearcherPsd(data) {
  return request({
    url: "/researcher/resetUserPwd",
    method: "post",
    data
  })
}

// 研究者 - 删除
export function deleteResearcher(data) {
  return request({
    url: "/researcher/delUser",
    method: "post",
    data
  })
}

// 受试者管理 - 获取项目列表
export function getProjectListInSub(data) {
  return request({
    url: "/examinee/getProjectList",
    method: "post",
    data
  })
}

// 受试者管理 - 根据项目ID统计受试者用户数量
export function getStatExaminee(data) {
  return request({
    url: "/examinee/statExaminee",
    method: "post",
    data
  })
}

// 受试者管理 - 根据项目ID获取受试者列表
export function getSubjectsByProID(data) {
  return request({
    url: "/examinee/getExamineeByProjectId",
    method: "post",
    data
  })
}

// 受试者管理 - 根据ID获取受试者详情
export function getSubjectDetailById(data) {
  return request({
    url: "/examinee/findExamineeDetails",
    method: "post",
    data
  })
}

// 审计管理 - 获取日志列表
export function getOperateLogsList(data) {
  return request({
    url: "/operateLog/getOperateLogsList",
    method: "post",
    data
  })
}

// ----------------------发放记录---------------------------------
// 获取发放记录 列表
export function getDistributionRecordlist(data) {
  return request({
    url: "/distribution_record/get_list",
    method: "post",
    data
  })
}

// 查看详情
export function getRecordDetail(data) {
  return request({
    url: "/distribution_record/get_details",
    method: "post",
    data
  })
}

// 获取明细中 成功/失败 笔数
export function getSuccessNumForRecord(data) {
  return request({
    url: "/distribution_record/get_success_num",
    method: "post",
    data
  })
}

// 同步发放结果
export function getSyncResult(data) {
  return request({
    url: "/issuing/syncresult",
    method: "post",
    data
  })
}

// 再次发放
export function getReissuing(data) {
  return request({
    url: "/issuing/reissuing",
    method: "post",
    data
  })
}