import { createStore } from 'vuex';
import settings from "./modules/settings";
import getters from "./getters";


export default createStore({
  state: {
    crcList: []
  },
  mutations: {
    UPDATE_CRC: (state, param) => {
      state.crcList = param;
    }
  },
  actions: {
  },
  modules: {
    settings,
  },
  getters
})
